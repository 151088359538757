















































































































































import {
  reactive, ref, Ref, SetupContext, defineComponent,
  onMounted, PropType, watch, computed, set,
} from '@vue/composition-api';
import _ from "lodash";
import PipelineList from "@/components/PipelineList.vue";
import * as Lexer from "@/models/lexer";
import * as Parser from "@/models/parser";
import * as MS from "@/models/minishell";
import * as Expander from "@/models/expander";
import * as EV from "@/models/envvar";

export default defineComponent({
  components: {
    PipelineList,
  },

  setup(prop: {
  }, context: SetupContext) {
    const varmap = EV.load_vars();

    const viewData: {
      mode: "prompt" | "variables"
      line_buffer: string;
      lines: string[];
      line_pointer: number;
      varmap: EV.ShellVarMap;
      assign_var_key: string;
      assign_var_value: string;
    } = reactive({
      mode: "prompt",
      line_buffer: "",
      lines: [],
      line_pointer: -1,
      varmap,
      assign_var_key: "",
      assign_var_value: "",
    });

    const eventHandlers = {
      readline: () => {
        setTimeout(() => {
          const str = (viewData.line_buffer || "").replaceAll(/\n/g, "") + "\n";
          console.log({ str })
          if (!str.trim()) { return; }
          // line_buffer -> present_line に追加
          viewData.lines.push(str);
          viewData.line_pointer = viewData.lines.length - 1 
          viewData.line_buffer = "";
        }, 10);
      }
    };
    const present_line = computed(() => {
      if (viewData.line_pointer < 0 || viewData.lines.length <= viewData.line_pointer) { return null; }
      return viewData.lines[viewData.line_pointer];
    });
    const present_line_fordisp = computed(() => {
      if (!present_line.value) { return "(none)"; }
      return present_line.value.replaceAll(/\t|\n/g, (c => {
        switch (c) {
          case "\t": return "\\t";
          case "\n": return "\\n";
        }
        return c;
      }));
    });

    /**
     * レキサにより得られたワードの連結リスト
     */
    const lexed_wordlist = computed(() => {
      const line = present_line.value;
      if (!line) { return null; }
      return Lexer.lexer(line);
    });

    /**
     * lexed_wordlist を配列にflattenしたもの
     */
    const lexed_flattened_wordlist = computed(() => {
      const flist: MS.WordList[] = [];
      let wlist = lexed_wordlist.value;
      while (wlist) {
        flist.push({ ...wlist, next: null });
        wlist = wlist.next;
      }
      return flist.slice(1);
    });

    const parse_words = (words: MS.WordList) => {
      const state = Parser.init_parser(words, varmap, false);
      Parser.parse(state);
      return state;
    }

    /**
     * パース結果
     */
    const parsed_state = computed(() => {
      const wordlist = lexed_wordlist.value;
      if (!wordlist || !wordlist.next) { return null; }
      const state = parse_words(wordlist.next);
      const flattened_pipelinelist = Parser.flatten_pipelinelist(state.pipelinelist);
      return {
        ...state,
        flattened_pipelinelist,
      };
    });

    /**
     * パース結果
     */
    const expanded_params_state = computed(() => {
      const wordlist = lexed_wordlist.value;
      if (!wordlist || !wordlist.next) { return null; }
      const state = parse_words(wordlist.next);
      Expander.expand_shell_param(state);
      const flattened_pipelinelist = Parser.flatten_pipelinelist(state.pipelinelist);
      return {
        ...state,
        flattened_pipelinelist,
      };
    });

    /**
     * 変数一覧 + 新規追加
     */
    const var_items = computed(() => {
      const items = _.map(viewData.varmap, (ent, key) => ent);
      items.push({
        key: "",
        value: "",
        is_env: false,
        attr: 0,
      });
      return items;
    });

    const unset_var = (key: string) => {
      EV.unset_var(viewData.varmap, key);
      EV.store_vars(viewData.varmap);
    };

    const assign_var = () => {
      const key = viewData.assign_var_key;
      const value = viewData.assign_var_value;
      if (!key) { return; }
      viewData.assign_var_key = "";
      viewData.assign_var_value = "";
      EV.assign_var(viewData.varmap, key, value);
      EV.store_vars(viewData.varmap);
    };

    const export_var = (key: string, on: boolean) => {
      if (on) {
        EV.export_var(viewData.varmap, key);
      } else {
        EV.unpublic_var(viewData.varmap, key);
      }
      EV.store_vars(viewData.varmap);
    };

    return {
      viewData,
      eventHandlers,
      present_line,
      present_line_fordisp,
      lexed_flattened_wordlist,
      parsed_state,
      expanded_params_state,

      var_items,
      assign_var,
      unset_var,
      export_var,
    };
  }
});
