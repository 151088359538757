


















































import _ from "lodash";
import { reactive, ref, Ref, SetupContext, defineComponent, onMounted, PropType, watch, computed } from '@vue/composition-api';
import * as Lexer from "@/models/lexer";
import * as Parser from "@/models/parser";
import * as MS from "@/models/minishell";
import * as EV from "@/models/envvar";

export default defineComponent({
  name: "pipeline-list",
  props: {
    flattened_pipelinelist: {
      type: Object,
      required: true,
    },
  },

  setup(prop: {
    flattened_pipelinelist: Parser.FlatPipelineList;
  }, context: SetupContext) {

    const reflat_subshell = (stree: MS.STree) => {
      const subshell = stree.subshell;
      if (!subshell) { return null; }
      return Parser.flatten_pipelinelist(subshell);
    };

    /**
     * リダイレクション演算子の説明
     */
    const get_desc_redir_op = (redir: MS.RedirList) => {
      switch (redir.op) {
        case "<": return "INPUT_FROM_FILE";
        case ">": return "OUTPUT_TO_FILE";
        case "<>": return "IN_AND_OUT_FILE";
        case ">>": return "APPEND_TO_FILE";
        case "<&": return "DUP_FOR_INPUT";
        case ">&": return "DUP_FOR_OUTPUT";
        case "<<": return "HEREDOC";
        case "<<-": return "HEREDOC_EXTAB";
      }
      return "* UNEXPECTED *"
    }
    const get_redir_fd = (redir: MS.RedirList) => {
      if (redir.operand_left) {
        return {
          fd: redir.operand_left.token,
          default: false,
        };
      }
      switch (redir.op) {
        case "<":
        case "<<":
        case "<<-":
        case "<&":
        case "<<":
          return {
            fd: "0",
            default: true,
          }
        default:
          return {
            fd: "1",
            default: true,
          }
      }
    }

    const get_redir_right_tokens = (redir: MS.RedirList) => {
      let right = redir.operand_right;
      const targets: string[] = [];
      while (true) {
        targets.push(right.token);
        if (!right.right) { break; }
        right = right.right;
      }
      return targets.join(", ");
    };

    return {
      reflat_subshell,
      get_desc_redir_op,
      get_redir_fd,
      get_redir_right_tokens,
    }
  },

})
